<template>
  <div>
  <v-app>
    <v-app-bar-nav-icon @click.stop="menu = !menu"></v-app-bar-nav-icon>
    <v-navigation-drawer
        v-model="menu"
        clipped
        app
        temporary
    >
      <div v-for="menu in generateMenu()" v-bind:key="menu.title">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense>
          <v-list-item
              v-for="item in menu.items"
              :key="item.title"
              link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <router-link :to="item.path">{{ item.title }}</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</div>
</template>

<script>

import Vue from "vue";

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    menu: true,
    menuSegments: [
      {
        title: "Analoog",
        admin: false,
        items: [
          { title: "PIP", icon: "mdi-camera-metering-matrix", path: "/apip/",loggedIn: true, admin: false },
          { title: "DSP - analog TX", icon: "mdi-tune-vertical", path: "/atx/", admin: true },
          { title: "PA", icon: "mdi-antenna", path: "/apa/", admin: true },
        ],
      },
      {
        title: "DVB",
        admin: false,
        items: [
          { title: "DVB-S(2) RX status", icon: "mdi-wifi", path: "/drx/", admin: false },
          { title: "Winterhill RX", icon: "mdi-wifi", path: "/winterhill/",loggedIn: true, admin: false },
        ],
      },
      {
        title: "Antenne",
        admin: false,
        items: [
          { title: "70cm antenne switch", icon: "mdi-antenna", path: "/coax-relay-70cm/",loggedIn: true, admin: false },
        ],
      },
      {
        title: "Admin",
        admin: true,
        items: [
          { title: "Dakluik", icon:"mdi-door", path: "/dakluik-controller/", admin: true },
          { title: "Grafana", icon:"mdi-chart-areaspline", path: "/grafana/", admin: true },
          { title: "Users", icon:"mdi-account-group", path: "/users", admin: true },
          { title: "Log", icon:"mdi-text-box-check-outline", path: "/auditlogs", admin: true },
          // { title: "Instellingen", icon:"mdi-cog-outline", path: "/admin/settings", admin: true },
        ],
      },
      {
        title: "Profiel",
        admin: false,
        items: [
          { title: "Uitloggen", icon:"mdi-logout", path: "/logout", loggedIn: true, admin: false },
          { title: "Inloggen", icon:"mdi-login", path: "/login", loggedOut: true, admin: false },
        ],
      },
    ],
  }),

  methods: {
    generateMenu() {
      const loggedIn = Vue.$cookies.get("token") !== null
      return this.menuSegments.map(segment => {
        return {
          title: segment.title,
          admin: segment.admin,
          items: segment.items.filter(item => {
            if (item.loggedIn && !loggedIn) {
              return false
            }
            if (item.loggedOut && loggedIn) {
              return false
            }
            if (item.admin) {
              return Vue.prototype.$claims !== undefined && Vue.prototype.$claims.is_admin
            }
            return true
          })
        }
      }).filter(segment => {
        // console.log("segment filter", segment)
        if (segment.admin) {
          // console.log("segment", segment, "requires admin. claims:", Vue.prototype.$claims)
          return Vue.prototype.$claims !== undefined && Vue.prototype.$claims.is_admin
        }
        return true
      })

    },
  },
};
</script>
