import { Buffer } from 'buffer';
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';
import LoginView from "@/views/LoginView";
import LogoutView from "@/views/LogoutView";
import Setup2FAView from "@/views/Setup2FAView";
import Verify2FAView from "@/views/Verify2FAView";
import PreVerifyEmailView from "@/views/PreVerifyEmailView";
import VerifyEmailView from "@/views/VerifyEmailView";
import ManageUsersView from "@/views/ManageUsersView";
import AuditLogView from "@/views/AuditLogView";
import HomeView from "@/views/HomeView";
import FrameView from "@/views/FrameView";

Vue.use(VueCookies);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '*',
    name: 'FrameView',
    component: FrameView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView,
  },
  {
    path: '/pre-verify-email',
    name: 'PreVerifyEmail',
    component: PreVerifyEmailView,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmailView,
  },
  {
    path: '/verify-2fa',
    name: 'Verify2FA',
    component: Verify2FAView,
  },
  {
    path: '/setup-2fa',
    name: 'Setup2FA',
    component: Setup2FAView,
  },
  {
    path: '/users',
    name: 'Users',
    component: ManageUsersView,
  },
  {
    path: '/auditlogs',
    name: 'AuditLogs',
    component: AuditLogView,
  },
]

const router = new VueRouter({
  routes
})

function parseJwt(token) {
  const base64Payload = token.split('.')[1];
  const payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}

function refreshToken() {
  // console.log("Refreshing token", Vue.$cookies.get("token"))
  fetch("/api/auth/token/refresh", {credentials: 'same-origin'})
      .then(response => {
        if (!response.ok) {
          console.log("failed to refresh token")
          Vue.$cookies.remove("token");
        }
        // console.log("refreshToken(): logged in:", response)
      })
}

router.beforeEach((to, from, next) => {
  console.log("router.beforeEach", "to.path", to.path, "to.query", to.query)
  if (to.path !== "/logout") {
    refreshToken();
  }
  // console.log("router:beforeEach", to, from, next)
  const token = Vue.$cookies.get("token");
  if (token) {
    // we have an auth token, parse it and store claims
    Vue.prototype.$claims = parseJwt(token);

    // handle half finished registrations
    if (!Vue.prototype.$claims.email_verified) {
      console.log("router: needs email verified")
      if (to.path === "/verify-email") {next(); return}
      if (to.path === "/pre-verify-email") {next(); return}
      next({name: 'VerifyEmail', query: {redirect: to.query['redirect']!==undefined?to.query['redirect']:to.path }})
      return
    }

    if(!Vue.prototype.$claims.second_factor_set) {
      console.log("router: needs 2fa configured")
      if (to.path === "/setup-2fa") {next(); return}
      next({name: 'Setup2FA', query: {redirect: to.query['redirect']!==undefined?to.query['redirect']:to.path }})
      return
    }

    if(!Vue.prototype.$claims.second_factor_ok) {
      console.log("router: wrong 2fa")
      if (to.path === "/verify-2fa") {next(); return;}
      next({name: 'Verify2FA', query: {redirect: to.query['redirect']!==undefined?to.query['redirect']:to.path }})
      return
    }
  }
  next()
})

export default router
