var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" <"),_c('span',[_vm._v(_vm._s(item.username))]),_vm._v("> ")]}},{key:"item.emailVerified",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.emailVerified),callback:function ($$v) {_vm.$set(item, "emailVerified", $$v)},expression:"item.emailVerified"}})]}},{key:"item.secondFactorVerified",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.secondFactorVerified),callback:function ($$v) {_vm.$set(item, "secondFactorVerified", $$v)},expression:"item.secondFactorVerified"}})]}},{key:"item.isDisabled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"id":'disable_' + item.username},on:{"click":function($event){return _vm.updateDisabled(item.username)}},model:{value:(item.isDisabled),callback:function ($$v) {_vm.$set(item, "isDisabled", $$v)},expression:"item.isDisabled"}})]}},{key:"item.PipAllowFull",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"id":'PipAllowFull_' + item.username},on:{"click":function($event){return _vm.updatePipAllowFull(item.username)}},model:{value:(item.pipAllowFull),callback:function ($$v) {_vm.$set(item, "pipAllowFull", $$v)},expression:"item.pipAllowFull"}})]}},{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isAdmin),callback:function ($$v) {_vm.$set(item, "isAdmin", $$v)},expression:"item.isAdmin"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.username)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }